<template>
	<div>
		<a-spin :spinning="loading">
      <div class="flex space alcenter">
        <div class="ft20 cl-main ftw600">{{getTitle}}</div>
        <a-button @click="$router.go(-1)">返回</a-button>
      </div>
			<div style="min-height: 800px;" class="bg-w flex mt20 bg-w">
				<div class="member-detail-left">
					<div class="member-detail-left-header">
						<div class="flex alcenter">
							<img v-if="detail.cover_img != null" :src="detail.cover_img" class="member-detail-face"/>
							<img v-else src="@/assets/image/woyaoyou_head_defult@2x.png" class="member-detail-face"/>
							<div class="ml15">
								<div class="flex alcenter ">
									<div class="ft16 ftw600 cl-main text-over4">{{detail.name}}</div>
                  <div class="member-detail-vip-iden ml4 text-over4">{{detail.gender_text}}</div>
								</div>
								
								<div class="mt15 flex alcenter">
                  <a-icon class="iconfont iconcall_gray mr5 ft12 cl-notice" type="environment" />
									<div class="cl-info ft12 ftw400 text-over4">{{detail.address}}</div>
								</div>
							</div>
						</div>
					</div>
					
					<div class="member-detail-left-content">

            <div class="member-detail-left-content-item mt10">
              <div class="flex space alcenter">
                <div class="ft14 ftw400 cl-main">年龄</div>
                <div class="ft14 ftw400 cl-theme">{{detail.ages}}岁</div>
              </div>
            </div>

            <div class="member-detail-left-content-item mt10">
              <div class="flex space alcenter">
                <div class="ft14 cl-main">手机号码</div>
                <div class="ft14 cl-theme">{{detail.hidden_mobile}}</div>
              </div>
            </div>

            <div class="member-detail-left-content-item mt10">
              <div class="flex space alcenter">
                <div class="ft14 ftw400 cl-main">身份证</div>
                <div class="ft14 ftw400 cl-theme">{{detail.hidden_idcard}}</div>
              </div>
            </div>

            <div class="member-detail-left-content-item mt10">
              <div class="flex space alcenter">
                <div class="ft14 ftw400 cl-main">重阳分</div>
                <div class="ft14 ftw400 cl-theme">{{detail.cyf}}</div>
              </div>
            </div>

            <div class="member-detail-left-content-item mt10">
              <div class="flex space alcenter">
                <div class="ft14 ftw400 cl-main">服务管家</div>
                <div class="ft14 ftw400 cl-theme">{{detail.serviceman}}</div>
              </div>
            </div>

            <div class="member-detail-left-content-item mt10">
              <div class="flex space alcenter">
                <div class="ft14 ftw400 cl-main">随访频率</div>
                <div class="ft14 ftw400 cl-theme">{{detail.access_rate}}天</div>
              </div>
            </div>

					</div>

          <div class="member-detail-left-footer">

            <div class="flex alcenter mt16">
              <div class="member-detail-left-footer-item " @click="integralDeductionAct()">
                <i class="iconfont iconoperation_cate05 flex center ft20" style="color: #E8B000;"/>
                <div class="ft12 ftw400 cl-main text-center">工单数</div>
                <div class="ft16 ftw600 cl-theme text-center">{{totals.appointment}}笔</div>
              </div>

              <div class="member-detail-left-footer-item ml16" @click="sendCouponAct()">
                <i class="iconfont iconhome_nav04 flex center ft20" style="color: #FE8340;"/>
                <div class="ft12 ftw400 cl-main text-center">随访数</div>
                <div class="ft16 ftw600 cl-theme text-center">{{totals.access}}次</div>
              </div>

              <div class="member-detail-left-footer-item ml16" @click="applyCardAct()">
                <i class="iconfont iconoperation_cate031 flex center ft20" style="color: #00C657;"/>
                <div class="ft12 ftw400 cl-main text-center">活动数</div>
                <div class="ft16 ftw600 cl-theme text-center">{{totals.active}}次</div>
              </div>
            </div>

          </div>

				</div>
			
				<div class="member-detail-right">
					<a-layout>
						<a-layout-header class="layout-classes-header">
							<a-menu class="classes-menu" :defaultSelectedKeys="[selectCurrent]" @click="selectMenu" mode="horizontal">
								<a-menu-item key="appointment">预约工单</a-menu-item>
								<a-menu-item key="access">随访记录</a-menu-item>
								<a-menu-item key="guardian">监护人</a-menu-item>
                <a-menu-item key="active">活动记录</a-menu-item>
                <a-menu-item key="info">档案详情</a-menu-item>
							</a-menu>
						</a-layout-header>
						<a-layout-content class="layout-classes-content">
							<div v-if="selectCurrent == 'appointment'">
								<appointment-detail :elder_id="elder_id"></appointment-detail>
							</div>
							<div v-if="selectCurrent == 'access'">
								<access-detail :elder_id="elder_id"></access-detail>
							</div>
							<div v-if="selectCurrent == 'guardian'">
								<guardian-detail :elder_id="elder_id"></guardian-detail>
							</div>
							<div v-if="selectCurrent == 'active'">
								<active-detail :elder_id="elder_id"></active-detail>
							</div>
              <div v-if="selectCurrent == 'info'">
                <elder-info :form="detail"></elder-info>
              </div>
						</a-layout-content>
					</a-layout>
				</div>

			</div>
		</a-spin>
	</div>
</template>

<script>
	import appointmentDetail from './components/elder/appointment.vue';
	import accessDetail from './components/elder/access.vue';
	import guardianDetail from './components/elder/guardian.vue';
	import activeDetail from './components/elder/active.vue';
	import elderInfo from './components/elder/info.vue';
	export default{
    computed:{
      getTitle(){
        return '长者详情';
      }
    },
		components:{
			appointmentDetail,
      accessDetail,
      guardianDetail,
      activeDetail,
      elderInfo,
		},
		data(){
			return{
				loading:false,
        elder_id:0,
				selectCurrent: 'appointment',
				balanceRechargeLoading:false,
				balanceDeductionLoading:false,
				applyCardLoading:false,
				integralRechargeLoading:false,
				integralDeductionLoading:false,
				sendCouponLoading:false,
				assignAdviserLoading:false,
				detail:{},
        totals:{}
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.elder_id=0;
			} else {
				this.elder_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		methods:{
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getElderDetail',{
					elder_id:this.elder_id
				}).then(res=>{
					this.detail=res.detail;
					this.totals=res.totals;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
					this.$router.go(-1);
				})
			},
			selectMenu(e) {
				this.selectCurrent = e.key;
			},
		}
	}
</script>

<style>
	.member-detail-left{
		width: 319px;
		min-height: 800px;
		border-right: 1px solid #EBEDF5;
	}
	.member-detail-left-header{
		/* height: 128px; */
		padding: 24px;
		border-bottom: 1px solid #EBEDF5;
	}
	
	.member-detail-left-header-action{
		width: 272px;
		height: 50px;
		background: #FFFFFF;
		border-radius: 8px;
		border: 1px solid #EBEDF5;
		padding: 0px 20px 0px 24px;
	}
	.member-detail-face{
		width: 80px;
		height: 80px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
		border-radius: 40px;
		border: 2px solid #FFFFFF;
	}
	.member-detail-vip-iden{
		padding: 2px 6px 2px 6px;
		border-radius: 2px;
		border: 1px solid #EBEDF5;
		
		font-size: 12px;
		font-weight: 600;
		color: #4772FF;
	}
	.member-detail-left-content{
		padding: 24px;
	}
	
	.member-detail-left-content-item{
		width: 100%;
		height: 42px;
		background: #F7F8FC;
		border-radius: 8px;
		padding: 10px;
	}
	
	.member-detail-left-footer{
		padding: 24px;
	}
	
	.member-detail-left-footer-item{
		width: 80px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		cursor: pointer;
		padding: 15px 10px 15px 10px;
	}
	
	.member-detail-right{
		width: 100%;
	}
	
	
	
	.layout-classes-header {
		background: #FFFFFF;
	}
	
	.layout-classes-header {
		padding: 0 20px;
		height: 58px;
		line-height: 57px;
		border-bottom: 1px solid #F0F3F5;
		width: 100%;
	}
	
	.layout-classes-content {
		padding: 0px 0px 10px 0px;
		background: #FFFFFF;
		width: 100%;
	}
	
	.classes-menu {
		border-bottom: none;
		line-height: 55px;
		font-size: 14px;
	}
</style>
