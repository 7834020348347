<template>
	<div>
		<a-modal :title="getTitle" :width="840" :visible="visible"  @cancel="handleCancel" >
      <template>
        <a-descriptions title="">
          <a-descriptions-item :label="items.title" v-for="(items,index) in detail" v-if="items.key != 'drugs'">
            {{items.value}}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider orientation="left">用药信息</a-divider>
        <div :label="items.title" v-for="(items,index) in detail" v-if="items.key == 'drugs'">
          <div v-for="(item,index2) in items.item">
            <p>
              {{item[0].title}}：{{item[0].value}}；
              {{item[1].title}}：{{item[1].value}}{{item[1].item[0]}}；
              {{item[2].title}}： {{item[2].value}}
            </p>
          </div>
        </div>

      </template>
		</a-modal>
	</div>
</template>

<script>
// import {listMixin} from '@/common/mixin/list';
  export default {
    // mixins:[listMixin],
		props: {
			visible:{
				type:Boolean,
				default:false,
			},
      access_id:{
        type:Number,
        default:0,
      }
		},
		data() {
			return {
        loading:false,
        detail:[]
			}
		},
		computed:{
			getTitle(){
        return "随访详情";
			},
		},
    created() {
      this.loaddata();
    },
		methods: {

      loaddata(){
        if(this.loading==true) return;
        this.loading=true;
        this.$http.api('admin/getElderAccessDetail',{
          access_id:this.access_id
        }).then(res=>{
          if(res.detail){
            this.detail=res.detail.content;
          }
          this.loading=false;
        }).catch(res=>{
          this.loading=false;
          this.$router.go(-1);
        })

      },

			/**
			 * 取消弹窗
			 */
			handleCancel() {
				this.$emit("cancel");
			},

		}
	}
</script>

<style>
	.edit-level-form-name .ant-input{
		height: 40px;
	}

	.edit-level-form-name .ant-input-affix-wrapper .ant-input-prefix {
	    position: absolute;
	    top: 50%;
	    z-index: 2;
	    display: flex;
	    align-items: center;
	    color: rgba(0, 0, 0, 0.65);
	    line-height: 0;
	    transform: translateY(-50%);
	    width: 50px;
		height: 40px;
	}

	.edit-level-form-name .ant-input-affix-wrapper .ant-input:not(:first-child) {
	    padding-left: 74px;
	}

	.edit-level-form-discount .ant-input-number {
		font-size: 12px;
		line-height: 40px;
		font-weight: 400;
		color: #A6AEC2;
		width: 480px;
		height: 40px;
		border-radius: 4px;

	}


	.edit-level-form-discount .ant-input-number-input {
		width: 480px;
		height: 40px;
		padding: 0 80px;
		border-radius: 4px;
	}

	.edit-level-form-discount .ant-input-number-handler-wrap {
		display: none;
	}

	.edit-level-form-discount .input-number-addon{
		height: 40px;
		padding-left: 16px;
		position: absolute;
		top: 0px;
		left: 0;
		text-align: left;
		line-height: 40px;

		font-size: 12px;
		font-weight: 400;
		color: #232529;
	}

	.edit-level-form-discount .input-number-footer{
		height: 40px;
		padding-right: 36px;
		position: absolute;
		top: 0px;
		right: 0;
		line-height: 40px;

		font-size: 12px;
		font-weight: 400;
		color: #656A75;
	}

	.edit-level-form-color-item{
		width: 20px;
		height: 20px;
		border-radius: 4px;
		margin-right: 10px;
		line-height: 24px;
		text-align: center;
	}

	.edit-level-form-color-item .active{
		box-shadow: 0px 2px 4px 0px rgba(220, 173, 133, 0.3);
	}

	.edit-level-form-or{
		width: 30px;
		height: 30px;
		background: rgba(71, 114, 255, 0.06);
		border-radius: 15px;

		font-size: 14px;
		font-weight: 500;
		color: #4772FF;
		line-height: 30px;
		text-align: center;
	}
</style>
