<template>
	<div>
		<div class="pd30">
      <div class="wxb-table-gray">
        <a-table rowKey="appointment_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
                 :data-source="datas" :loading="loading">
          <template slot="action" slot-scope="record,index">
            <div class="flex center">
              <a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a class="menu-act" href="javascript:;" @click="showDetailAct(record)">
                      <i class="iconfont ft14 iconsee"></i>
                      <span class="ml10">查看详情</span>
                    </a>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </template>
        </a-table>
      </div>
		</div>

    <div v-if="addAppointmentVisible">
      <add-appointment :member_id="member_id" :visible="addAppointmentVisible" @cancel="cancelAddAppointment" @ok="okAddAppointment"></add-appointment>
    </div>

	</div>
</template>

<script>
  import {listMixin} from '@/common/mixin/list';
	export default {
		props:{
      elder_id:{
				type:Number,
				default:0
			}
		},
		mixins: [listMixin],
		components:{},
		data() {
			return {
				loading: false,
				addAppointmentVisible:false,
				cancelAppointVisible:false,
				distributionServicemanVisible:false,
				completeAppointVisible:false,
				order:{},
				appointment_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: 'ID',dataIndex: 'application_id',align: 'center'},
					{title: '活动名称',dataIndex: 'name',align: 'center'},
          {title: '活动联系人',dataIndex: 'contacts',align: 'center'},
					{title: '报名时间',dataIndex: 'application_time',align: 'center'},
					{title: '签到时间',dataIndex: 'sign_time',align: 'center'},
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getElderActiveList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
          elder_id:this.elder_id,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

			//新增预约
			addAppointmentAct(){
				this.addAppointmentVisible=true;
			},
			cancelAddAppointment(){
				this.addAppointmentVisible=false;
			},
			okAddAppointment(){
				this.addAppointmentVisible=false;
				this.getLists();
			},

			//查看订单详情
			showDetailAct(record){
				this.$router.push('/appointment/orderDetail?id='+record.appointment_id)
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-cover-img{
		width: 80px;
		height: 60px;
	}
	.member-face{
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}
</style>
