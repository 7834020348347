<template>
	<div>
		<div class="pd30">
<!--			<div>-->
<!--				<a-button type="primary" icon="plus" @click="addAppointmentAct()">新增预约</a-button>-->
<!--			</div>-->
			<div class="">
				<div class="wxb-table-gray">
					<a-table rowKey="appointment_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
						:data-source="datas" :loading="loading">

						<template slot="action" slot-scope="record,index">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
									<a-menu slot="overlay">
										<a-menu-item>
											<a class="menu-act" href="javascript:;" @click="showDetailAct(record)">
												<i class="iconfont ft14 iconsee"></i>
												<span class="ml10">查看详情</span>
											</a>
										</a-menu-item>
									</a-menu>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
		</div>

    <div v-if="addAppointmentVisible">
      <access-detail :access_id="access_id" :visible="addAppointmentVisible" @cancel="cancelAddAppointment" @ok="okAddAppointment"></access-detail>
    </div>

	</div>
</template>

<script>
  import {listMixin} from '@/common/mixin/list';
	import accessDetail from '../../../member/components/elder/modal/accessDetail.vue';
	export default {
		props:{
      elder_id:{
				type:Number,
				default:0
			}
		},
		mixins: [listMixin],
		components:{
      accessDetail,
		},
		data() {
			return {
				loading: false,
				addAppointmentVisible:false,
				order:{},
        access_id:0,
        pagination: {
          current: 1,
          pageSize: 10, //每页中显示10条数据
          total: 0,
        },
				columns: [
					{title: 'ID',dataIndex: 'access_id',align: 'center'},
          {title: '操作时间',dataIndex: 'add_time_format',align: 'center'},
					{title: '随访时间',dataIndex: 'access_time_forma',align: 'center'},
					{title: '随访长者',dataIndex: 'elder_name',align: 'center'},
					{title: '服务人员',dataIndex: 'personnel',align: 'center'},
					{title: '备注',dataIndex: 'access_notes',align: 'left',ellipsis: true},
					{title: '状态',dataIndex: 'status_txt',align: 'center'},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getElderAccessList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
          elder_id:this.elder_id,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},

      showDetailAct(record){
        this.access_id=record.access_id;
        this.addAppointmentVisible=true;
      },

      cancelAddAppointment(){
        this.addAppointmentVisible=false;
      },

      okAddAppointment(){
        this.addAppointmentVisible=false;
      },

			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-cover-img{
		width: 80px;
		height: 60px;
	}
	.member-face{
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}
</style>
