<template>
	<div>
		<div class="pd30">
			<div class="">
				<div class="wxb-table-gray">
					<a-table rowKey="appointment_id" :columns="columns" :pagination="pagination" @change="handleTableChange"
						:data-source="datas" :loading="loading">
						<template slot="action" slot-scope="record,index">
							<div class="flex center">
								<a-dropdown placement="bottomRight">
									<span class="more-act">
										<i class="iconfont iconmore_gray"></i>
									</span>
								</a-dropdown>
							</div>
						</template>
					</a-table>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
  import {listMixin} from '@/common/mixin/list';
	export default {
		props:{
      elder_id:{
				type:Number,
				default:0
			}
		},
		mixins: [listMixin],
		components:{},
		data() {
			return {
				loading: false,
				addAppointmentVisible:false,
				cancelAppointVisible:false,
				distributionServicemanVisible:false,
				completeAppointVisible:false,
				order:{},
				appointment_id:0,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				columns: [
					{title: 'ID',dataIndex: 'guardian_id',align: 'center'},
					{title: '监护人姓名',dataIndex: 'name',align: 'center'},
					{title: '监护人电话',dataIndex: 'tel',align: 'center'},
					{title: '居住地址',dataIndex: 'address',align: 'center'},
					{title: '与长者关系',dataIndex: 'guanxi_txt',align: 'center'},
				],
				datas: [],
			}
		},
		methods: {
			getLists() {
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getElderGuardianList',{
					limit:this.pagination.pageSize,
					page:this.pagination.current,
          elder_id:this.elder_id,
				}).then(res=>{
					this.pagination.total=res.total;
					this.datas=res.list;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
		}
	}
</script>

<style>
	.projetc-cover-img{
		width: 80px;
		height: 60px;
	}
	.member-face{
		width: 40px;
		height: 40px;
		border-radius: 20px;
	}
</style>
