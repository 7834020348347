<template>
  <div class="">
    <a-spin :spinning="loading">

      <div class="bg-w pd20_40">

        <div style="width: 1000px;">

          <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-row>
              <a-col :span="8">
                <a-form-model-item label="姓名">
                  {{form.name}}
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="别名">
                  {{form.alias_name}}
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="手机号码">
                 ：{{form.mobile}}
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="固定电话">
                  {{form.phone}}
                </a-form-model-item>
              </a-col>

              <a-col :span="8">
                <a-form-model-item label="性别" >{{form.gender_text}}</a-form-model-item>
              </a-col>

              <a-col :span="8">
                <a-form-model-item required label="身份证号"> ： {{form.id_card}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="服务管家" > ：{{form.serviceman}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="照料人" >{{form.guardian_type}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="随访频率">：{{form.access_rate}}天</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="户籍详址">{{form.addressdom}}</a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="现居详址">{{form.address}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>

              <a-col :span="8">
                <a-form-model-item label="个人特长">{{form.speciality}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="民族">{{form.nation}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="婚姻状况" >：{{form.marriage}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="文化程度" >{{form.education}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="政治面貌" >{{form.politics}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="职业" >{{form.career}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                  <a-form-model-item label="政治面貌" >{{form.character}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="爱好">{{form.interest}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="子女数">{{form.children_num}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="健在" >{{form.exist}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="经济来源" >{{form.financial}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="救助类型" >{{form.rescue}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="医保情况" >{{form.guarantee}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="宠物" >{{form.pet_type}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="重阳分">{{form.cyf}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="居住情况" >：{{form.live_type}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="同住情况" >：{{form.family_type}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="养老方式" >：{{form.retirement}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="燃料类型" >：{{form.fuel_type}}</a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="饮水类型" >：{{form.water_type}}</a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="8">
                <a-form-model-item label="厨房排风" >{{form.kitchen_type}}</a-form-model-item>
              </a-col>

              <a-col :span="8"></a-col>
              <a-col :span="8"></a-col>
            </a-row>

          </a-form-model>

        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {listMixin} from '@/common/mixin/list';
export default {
  props:{
    form:{
      type:Object,
    }
  },
  mixins: [listMixin],
  components:{},
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
    }
  },
  methods: {}
}
</script>

<style>
.projetc-cover-img{
  width: 80px;
  height: 60px;
}
.member-face{
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
</style>
